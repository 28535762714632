@import '../../../styles/variables.scss';

.containerTemplate {
    .header {
        width: 100%;
    }

    > main {
        min-height: calc(
            100vh - #{$footer-height-mobile} - #{$header-height-mobile}
        );
        width: 100%;
        overflow: auto;
        padding: 10px 20px;
        margin-right: auto;
        margin-left: auto;
        max-width: 1160px;
    }

    .footer {
        width: 100%;
    }

    @include respond-to('small') {
        > main {
            min-height: calc(100vh - #{$footer-height} - #{$header-height});
        }
    }
}
