@import '../../../styles/variables.scss';

.basicPageTemplate {
    .header {
        width: 100%;
    }

    > main {
        width: 100%;
        overflow: auto;
    }

    .content {
        width: 100%;
        margin: 20px auto 40px;
        max-width: 1160px;
        padding: 0 20px;
    }

    .whyUs {
        padding-bottom: 10px;
    }

    .contactUs {
        background-color: $background-secondary-colour;
        border-top: 1px solid #444;
        border-bottom: 1px solid #444;
        width: 100%;
        padding-bottom: 20px;
    }

    .content {
        margin-top: 30px;
    }

    .footer {
        width: 100%;
    }

    @include respond-to('large') {
        .content {
            padding: 0;
        }

        .whyUs {
            padding: 0 0 20px;
        }
    }
}
