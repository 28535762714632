@import '../../styles/variables.scss';

.expandableSection {
    display: flex;
    flex-direction: column;

    .header {
        height: 60px;
        width: 100%;
        margin: 0;
        padding-right: 5px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $brand-primary-colour;
        cursor: pointer;
        transition: background-color 0.1s linear;
        -webkit-tap-highlight-color: transparent;

        &:active {
            background-color: #eee;
        }

        .button {
            transition: transform 0.1s ease-in-out;
            transform: rotate(45deg);
            &:after {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                border: solid $brand-primary-colour;
                border-width: 0 3px 3px 0;
            }
        }
    }

    .content {
        max-height: 0;
        width: 100%;
        overflow: hidden;
        transition: all 0.3s ease-in;

        > *:first-child {
            margin-top: 20px;
        }
    }

    &.isOpen {
        .header {
            .button {
                transform: rotate(-135deg);
            }
        }

        .content {
            max-height: 5000px;
        }
    }
}
